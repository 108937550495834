import React, {useState, useRef, useEffect} from 'react';
import Draggable from 'react-draggable';

function App() {
  const refEprContent = useRef();
  const refPipeta1 = useRef();
  const refPipeta2 = useRef();
  const refPipetaContent1 = useRef();
  const refPipetaContent2 = useRef();
  const refPrecipitat = useRef();
  const [position1, setPosition1] = useState({ x: 0, y: 0 });
  const [position2, setPosition2] = useState({ x: 0, y: 0 });
  const [step, setStep] = useState(0);
  //const [drag1,setDrag1] = useState(true)

  const startPos = (e,data) => {
    if(e===1 && step===0) {
      refPipetaContent1.current.style.height  ="100px";
    }
    if(e===2 && step === 1) {
      refPipetaContent2.current.style.height  ="100px";
    }
  }

  const trackPos = (e,data) => {
    //alert(e)
    if(e===1) {
      refPipetaContent1.current.style.height  ="100px";
      //setPosition1({ x: data.x, y: data.y });
    }
    if(e===2) {
      refPipetaContent2.current.style.height  ="100px";
      // setPosition2({ x: data.x, y: data.y });
    }
  };

  const stopPos = (e,data) => {
    // console.log(step);
    // console.log(e);
    if(step===0 && e===1) {
      if(data.x > -500 && data.x <-350) {
        setPosition1({x:-411, y:-200})
        setStep(1);
      } else {
        setPosition1({x:0, y:0})
      }
    } else if(step===1 && e===2) {
      if(data.x > -400 && data.x <-250) {
        setPosition2({x:-311, y:-200})
        setStep(2);
      } else {
        setPosition2({x:0, y:0})
      }
    } else {
      setPosition1({x:0, y:0})
      setPosition2({x:0, y:0})
    }
  };
  
  useEffect(()=>{
    let timer1;
    let timer2;
    if(step===1) {
      refPipetaContent1.current.style.height  ="0";
      refEprContent.current.style.height  ="40px";
      timer1 = setTimeout(function(){
        setPosition1({x:0, y:0})
      },1500)
    }
    if(step === 2) {
      refPipetaContent2.current.style.height  ="0";
      refEprContent.current.style.height  ="80px";
      
      timer2 = setTimeout(function(){
        refPrecipitat.current.style.opacity = 0.8;
        refPrecipitat.current.style.animationName = 'updown';
        setPosition2({x:0, y:0})
      },1500)
    }
    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
    }
  },[step])

  return (
    <div className="App">
      <header className="App-header">
        <div className="screen">
          <div className="top-info">
            <div className="top-title">scara de culoare a turnesolului</div>
            <div className="scara-turnesol"></div>
            <div className="scara-txt">
              <span>acid</span>
              <span>baza</span>
            </div>
          </div>
          <img
            className="eprubeta"
            src="/images/eprubeta_goala.gif"
            alt=""
          />
          <img  className="precipitat" ref={refPrecipitat} src="/images/precipitat.gif" alt="" />
          <div ref={refEprContent} className="eprubeta-content">
            
          </div>
          <div className="table">
          </div>
          <div className="sticla1">
            <img  className="dop" src="/images/dop1.gif" alt="" />
            <img  className="sticla" src="/images/empty_bollte2.gif" alt="" />
            <div className="sticla-content"></div>
            <div className="sticla-text">CuCl2</div>
          </div>
          <div className="sticla2">
            <img  className="dop" src="/images/dop2.gif" alt="" />
            <img  className="sticla" src="/images/empty_bollte2.gif" alt="" />
            <div className="sticla-content"></div>
            <div className="sticla-text">NaOH</div>
            
          </div>
          <Draggable 
            position = {position1}
            //onDrag={(e, data) => trackPos(1,data)} 
            onStop={(e, data) => stopPos(1,data)} 
            onStart={(e, data) => startPos(1,data)} 
            handle=".pipeta1"
            scale={1}
          >
              <div ref={refPipeta1} className="pipeta pipeta1">
                <img  className="pipeta-goala" src="/images/pipeta_goala.gif" alt="" />
                <div className="pipeta-content" ref={refPipetaContent1} >
                  
                </div>
              </div>
          </Draggable>
          <Draggable 
            position = {position2}
            //onDrag={(e, data) => trackPos(2,data)} 
            onStop={(e, data) => stopPos(2,data)}
            onStart={(e, data) => startPos(2,data)}  
            handle=".pipeta2"
            scale={1}
          >
              <div ref={refPipeta2} className="pipeta pipeta2">
                <img  className="pipeta-goala" src="/images/pipeta_goala.gif" alt="" />
                <div className="pipeta-content" ref={refPipetaContent2} >
                  {/* x:{position2.x} y:{position2.y} */}
                </div>
              </div>
          </Draggable>
        </div>
      </header>
    </div>
  );
}

export default App;
